<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("Staff_Formations") }}</h1>
      <br>
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="staffHasFormations">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
           
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ staffHasFormations.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : staffHasFormations.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
            <vs-th sort-key="staff">{{ $t("staff") }}</vs-th>
            <vs-th sort-key="company">{{ $t("company") }}</vs-th>
            <vs-th sort-key="function">{{ $t("function") }}</vs-th>
            <vs-th sort-key="formation">{{ $t("formation") }}</vs-th>
            <vs-th sort-key="organisme_formateur">{{ $t("organisme_formateur") }}</vs-th>
            <vs-th sort-key="idCertificateReferences">{{ $t("idCertificateReferences") }}</vs-th>

            <vs-th sort-key="date_formation">{{ $t("date_formation") }}</vs-th>
            <vs-th sort-key="date_obtention">{{ $t("date_obtention") }}</vs-th>
            <vs-th sort-key="date_validite">{{ $t("date_validite") }}</vs-th>
            <vs-th sort-key="dateOfTheNextUpgrades">{{ $t("dateOfTheNextUpgrades") }}</vs-th>

            <vs-th >Action</vs-th>

        </template>
  
        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.fullName}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.company !== null ? tr.company.name : '-'}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.function.name}}</p>
            </vs-td>
            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="formations in tr.staffHasFormations" :key="formations" class="product-name font-medium truncate">{{ formations.formation.name}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>

            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="trainerOrganisms in tr.staffHasFormations" :key="trainerOrganisms" class="product-name font-medium truncate">{{ trainerOrganisms.trainerOrganism}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>
            
            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="idCertificateReferences in tr.staffHasFormations" :key="idCertificateReferences" class="product-name font-medium truncate">{{ idCertificateReferences.idCertificateReference}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>
            
            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="dateOfTrainings in tr.staffHasFormations" :key="dateOfTrainings" class="product-name font-medium truncate">{{ dateOfTrainings.dateOfTraining}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
           </vs-td>
            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="dateOfObtainings in tr.staffHasFormations" :key="dateOfObtainings" class="product-name font-medium truncate">{{ dateOfObtainings.dateOfObtaining}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>
           
            <vs-td>
              <p v-if="tr.staffHasFormations.length" v-for="validityDates in tr.staffHasFormations" :key="validityDates" class="product-name font-medium truncate"><vs-chip :color="getOrderStatusColor1(validityDates.validityDate)" class="product-order-status">{{ validityDates.validityDate}}</vs-chip></p>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>

            <vs-td>
              <li v-if="tr.staffHasFormations.length" v-for="dateOfTheNextUpgrades in tr.staffHasFormations" :key="dateOfTheNextUpgrades" class="product-name font-medium truncate">{{ dateOfTheNextUpgrades.dateOfTheNextUpgrade}}</li>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
            </vs-td>
            
            <vs-td>
              <p v-if="!tr.staffHasFormations.length" class="product-name font-medium truncate">-</p>
              <p class="mt-2" v-if="tr.staffHasFormations.length" v-for="urls in tr.staffHasFormationProofs" :key="urls"><vs-button @click="showImage(urls)" type="filled" icon-pack="feather" icon="icon-eye" size="small">{{$t("Voir")}}</vs-button></p>
        
            </vs-td>

          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </template>
<script>
import https from 'node-fetch'
const date = 'https://worldtimeapi.org/api/timezone/Africa/Lome'
const requestOptions = {
  method: 'GET',
  header: {'Access-Control-Allow-Origin': 'GET'}
}
export default {
  
  data () {
    return {
      time_GMT:'',
      staffHasFormations: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.staffHasFormations.length
    }
  },
  methods: {
    showImage (file) {
      window.open(`https://dev.jra.mitigarisk.com/digiraDev${file}`, '_blank')
    },
    getOrderStatusColor1 (date) {
      if ((new Date(date))<(new Date(this.time_GMT)))   return 'danger'
      if ((new Date(date))>(new Date(this.time_GMT))) return 'success'
      return 'primary'
    },
    dateCheckFirst () {
      https(date, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
          this.time_GMT = data.datetime 
        })
        .catch(function () {

        }) 
    },
    
    getAllstaffHasFormations () {
      this.$vs.loading()
      this.$http.get('staffs/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            const obj = {
              fullName: event.fullName,
              function:event.function,
              company:event.company,
              staffHasFormations:event.staffHasFormations,
              staffHasFormationProofs:[]
            }
            if (event.staffHasFormations.length) {
              for (let i = 0; i < event.staffHasFormations.length; i++) {
                obj.staffHasFormationProofs.push(event.staffHasFormations[i].staffHasFormationProofs[0].url.substring(8))
              }
            }
            this.staffHasFormations.push(obj)
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  async created () {
    window.staffHasFormations = this
    const date = new Date()
    this.time_GMT = date.toISOString().split('T')[0]
    //this.dateCheckFirst()
    this.getAllstaffHasFormations()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  
  
  <style scoped>
  
  </style>
  
  <style scoped>
  
  </style>
  